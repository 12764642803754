import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';


export interface SeoMetaTags {
  title?: string,
  ogTitle?: string,
  description?: string,
  ogDescription?: string,
  keywords?: string,
  image?: string | any
}

@Injectable({
  providedIn: 'root'
})
export class MetasService {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private title: Title,
    private meta: Meta,
    private api: ApiService,
  ) { }

  setTitle(titleName: any) {
    this.title.setTitle(titleName);
  }

  setTags(tags: any) {
    this.meta.addTags(tags)
  }

  setNoIndexTag() {
    this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
  }

  removeNoIndexTag() {
    this.meta.removeTag("name='robots'")
  }

  setLinkTag(attrs: any) {
    try {
      let link: HTMLLinkElement = this.document.createElement('link');
      for (let key in attrs) {
        link.setAttribute(key, attrs[key]);
        this.document.head.appendChild(link);
      }
    } catch (e) { }
  }

  setMetaTags(metas: SeoMetaTags, appendSiteNameOnTitle: boolean = true) {
    const title = metas.title ? metas.title + (appendSiteNameOnTitle ? ' | HealthyBazar' : '') : 'HealthyBazar';
    let ogTitle;
    if (metas.ogTitle) ogTitle = metas.ogTitle + (appendSiteNameOnTitle ? ' | HealthyBazar' : '');

    const image = metas.image || environment.imageUrl + 'hb-social-image.png';

    this.title.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: ogTitle || title });
    this.meta.updateTag({ name: 'twitter:title', content: ogTitle || title });

    if (metas.description) {
      let desc = this.removeHTMLTags(metas.description)
      let ogDesc = this.removeHTMLTags(metas.ogDescription || '')

      this.meta.updateTag({ name: 'description', content: desc });
      this.meta.updateTag({ property: 'og:description', content: ogDesc || desc });
      this.meta.updateTag({ name: 'twitter:description', content: ogDesc || desc });
    } else {
      this.meta.removeTag("name='description'")
      this.meta.removeTag("property='og:description'")
      this.meta.removeTag("name='twitter:description'")
    }
    if (metas.keywords) {
      this.meta.updateTag({ name: 'keywords', content: metas.keywords });
    } else {
      this.meta.removeTag("name='keywords'")
    }
    this.meta.updateTag({ name: 'image', content: image });
    this.meta.updateTag({ property: 'og:image', content: image });
    this.meta.updateTag({ name: 'twitter:image', content: image });

    this.removeLinkTags(['[rel="image_src"]'])

    this.setLinkTag({ rel: 'image_src', href: image });
  }

  setCanonical(url: string) {
    this.meta.updateTag({ property: 'og:url', content: url });

    this.removeLinkTags(['[rel="canonical"]', '[rel="alternate"]']);
    this.setLinkTag({ rel: 'canonical', href: url });
    // this.setLinkTag({ rel: 'alternate', href: url, hreflang: 'en-IN' });
  }

  removeLinkTags(selectors: string[]) {
    selectors.forEach(selector => {
      let elem = this.document.querySelector(selector);
      elem && elem.remove();
    })
  }

  removeHTMLTags(htmlString: any): string {
    return (htmlString || '').replace(/<[^>]*>?/gm, '')
  }

  setPageMeta(name: string, fallbackTitle?: string) {
    this.api.get<{ success: boolean, data: any }>('metacontents/detail?name=' + name).subscribe((res: any) => {
      let { data, success } = res
      if (success) {
        let { metaTitle, metaDescription } = data || {};
        this.setMetaTags({
          title: metaTitle || fallbackTitle,
          description: metaDescription,
        })
      }
    }, (err: HttpErrorResponse) => {

    })
  }
}
